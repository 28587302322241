export const LogoNoText = (props: React.ButtonHTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="184.65 124.78 135.02 167.1"
    {...props}
  >
    <g>
      <circle
        r={47.006}
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#004cf2",
          fillRule: "evenodd",
          opacity: 0.8,
        }}
        transform="matrix(.36828 -1.14482 1.14482 .36828 259.107 219.644)"
      />
      <circle
        r={47.006}
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#fba536",
          fillRule: "evenodd",
          opacity: 0.8,
        }}
        transform="matrix(.23086 -.71765 .71765 .23086 246.835 180.65)"
      />
      <circle
        r={47.006}
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#ff5362",
          fillRule: "evenodd",
          opacity: 0.7,
        }}
        transform="matrix(.05391 -.16759 .16759 .05391 192.94 185.95)"
      />
      <circle
        r={47.006}
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#6319c3",
          fillRule: "evenodd",
          opacity: 0.8,
        }}
        transform="matrix(.29133 -.9056 .9056 .29133 240.445 230.82)"
      />
      <circle
        r={47.006}
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#ff5362",
          fillRule: "evenodd",
          opacity: 0.8,
        }}
        transform="matrix(.21268 -.66112 .66112 .21268 286.998 201.82)"
      />
      <circle
        r={47.006}
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#6319c3",
          fillRule: "evenodd",
          opacity: 0.7,
        }}
        transform="matrix(.02579 -.08017 -.08017 -.02579 237.145 287.914)"
      />
      <circle
        r={47.006}
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#ff5362",
          fillRule: "evenodd",
          opacity: 0.7,
        }}
        transform="matrix(.0295 -.0917 .0917 .0295 291.087 156.368)"
      />
      <circle
        r={47.006}
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#004cf2",
          fillRule: "evenodd",
          opacity: 0.7,
        }}
        transform="matrix(.06248 -.19421 .19421 .06248 280.618 134.388)"
      />
      <circle
        r={47.006}
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#fba536",
          fillRule: "evenodd",
          opacity: 0.7,
        }}
        transform="matrix(.03245 -.10088 .10088 .03245 287.237 286.487)"
      />
    </g>
    <circle
      r={47.006}
      style={{
        stroke: "none",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: "#f1f3f2",
        fillRule: "evenodd",
        opacity: 0.5,
      }}
      transform="matrix(1.11156 0 0 1.11156 253.266 217.775)"
    />
    <circle
      r={47.006}
      style={{
        stroke: "none",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: "#55665e",
        fillRule: "evenodd",
        opacity: 1,
      }}
      transform="matrix(1.02081 0 0 1.02081 249.5 218.01)"
    />
    <circle
      r={47.006}
      style={{
        stroke: "none",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: "#55665e",
        fillRule: "evenodd",
        opacity: 0.2,
      }}
      transform="matrix(1.02081 0 0 1.02081 249 213.01)"
    />
    <path
      d="M50 957.362c-24.817 0-45 20.183-45 45s20.183 45 45 45 45-20.183 45-45-20.183-45-45-45zm0 6c21.575 0 39 17.426 39 39 0 21.575-17.425 39-39 39s-39-17.425-39-39c0-21.574 17.425-39 39-39z"
      style={{
        stroke: "none",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: "#f1f3f2",
        fillRule: "nonzero",
        opacity: 0.19,
      }}
      transform="matrix(1.17743 0 0 1.17743 191.629 -964.2)"
    />
    <g>
      <path
        d="M79.8 25.15 62 7.33a7.47 7.47 0 0 0-5.26-2.18H25.38A7.39 7.39 0 0 0 18 12.51v75a7.39 7.39 0 0 0 7.36 7.36h49.26A7.39 7.39 0 0 0 82 87.49V30.41a7.4 7.4 0 0 0-2.2-5.26ZM36.07 12A12.11 12.11 0 1 1 24 24.14 12.11 12.11 0 0 1 36.07 12ZM25 68a2.92 2.92 0 0 1 2.92-2.92h21a2.92 2.92 0 0 1 0 5.84h-21A2.92 2.92 0 0 1 25 68Zm47.08-7.08H27.92a2.92 2.92 0 0 1 0-5.84h44.16a2.92 2.92 0 0 1 0 5.84Zm0-10H27.92a2.92 2.92 0 0 1 0-5.84h44.16a2.92 2.92 0 0 1 0 5.84Z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#f1f3f2",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(.55033 0 0 .55033 223.259 190.768)"
      />
      <path
        d="M31.74 30.54a3 3 0 0 0 5.13 0l5.88-9.68a3 3 0 0 0-5.12-3.12l-3.34 5.49a3 3 0 0 0-4.9 3.45Z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#f1f3f2",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(.55033 0 0 .55033 223.259 190.768)"
      />
    </g>
  </svg>
);
